<template>
 <!-- v-if="total > pageSize" -->
	<div>
		<ul class="mo-paging">
        <!-- prev -->
        <li  :class="['paging-item', 'paging-item--prev', {'paging-item--disabled' : index1 === 1}]"  @click="prev">首页</li>
        
        <!-- first -->
        <li :class="['paging-item', 'paging-item--first', {'paging-item--disabled' : index1 === 1}]" @click="first"> << </li>
        
        <li :class="['paging-item', 'paging-item--more']" v-if="showPrevMore">...</li>

        <li :class="['paging-item', {'paging-item--current' : index1 === pager}]" v-for="(pager,index) in pagers" :key="index" @click="go(pager)">{{ pager }}</li>

        <li :class="['paging-item', 'paging-item--more']" v-if="showNextMore">...</li>
        
        <!-- last -->
        <li :class="['paging-item', 'paging-item--last', {'paging-item--disabled' : index1 === pages}]"  @click="last"> >> </li>

        <!-- next -->
        <li
        :class="['paging-item', 'paging-item--next', {'paging-item--disabled' : index1 === pages}]"
        @click="next">末页</li>
    </ul>
	
	</div>
</template>

<script>
export default {
    name : 'pagination',
    //通过props来接受从父组件传递过来的值
    props : {
        //页面中的可见页码，其他的以...替代, 必须是奇数
        perPages : { 
            type : Number,
            default : 5 
        },

        //当前页码
        pageIndex : {
            type : Number,
            default : 1
        },

        //每页显示条数
        pageSize : {
            type : Number,
            default : 10
        },

        //总记录数
        total : {
            type : Number,
            default : 1
        },

    },
   
    methods : {
        prev(){
            if (this.index1 > 1) {
                this.go(this.index1 - 1)
            }
        },
        next(){
            if (this.index1 < this.pages) {
                this.go(this.index1 + 1)
            }
        },
        first(){
            if (this.index1 !== 1) {
                this.go(1)
            }
        },
        last(){
            if (this.index1 != this.pages) {
                this.go(this.pages)
            }
        },
        go (page) {
            if (this.index1 !== page) {
                this.index1 = page
                //父组件通过change方法来接受当前的页码
                this.$emit('change', this.index1)
            }
        }
    },
    computed : {

        //计算总页码
        pages(){
			console.log(11, this.size, this.limit);
            return Math.ceil(this.size / this.limit)
        },

        //计算页码，当count等变化时自动计算
        pagers () {
            const array = []
            const perPages = this.perPages
            const pageCount = this.pages
            let current = this.index1
            const _offset = (perPages - 1) / 2
            
            const offset = {
                start : current - _offset,
                end   : current + _offset
            }

            //-1, 3
            if (offset.start < 1) {
                offset.end = offset.end + (1 - offset.start)
                offset.start = 1
            }
            if (offset.end > pageCount) {
                offset.start = offset.start - (offset.end - pageCount)
                offset.end = pageCount
            }
            if (offset.start < 1) offset.start = 1

            this.showPrevMore = (offset.start > 1)
            this.showNextMore = (offset.end < pageCount)

            for (let i = offset.start; i <= offset.end; i++) {
                array.push(i)
            }

            return array
        }
    },
    data () {
        return {
            index1 : this.pageIndex, //当前页码
            limit : this.pageSize, //每页显示条数
            size : this.total || 1, //总记录数
            showPrevMore : false,
            showNextMore : false
        }
    },
    watch : {
        pageIndex(val) {
            this.index1 = val || 1
        },
        pageSize(val) {
            this.limit = val || 10
        },
        total(val) {
            this.size = val || 1
        }
    }

}
</script>

<style>
.mo-paging {
	display: flex;
	justify-content: center;
    padding: 0;
    margin-top: .3rem;
    font-size: 0;
    list-style: none;
    user-select: none;
    
}
.mo-paging  .paging-item {
        display: inline;
        font-size: 14px;
        position: relative;
        padding: .12rem .2rem;
        line-height: 1.42857143;
        text-decoration: none;
        border: 1px solid #eeeeee;
        background-color: #ebebeb;
        margin-left: .1rem;
        cursor: pointer;
        color: #999999;
        
    }
    .paging-item:first-child {
            margin-left: 0;
        }
        .paging-item:hover {
            background-color: #a21003;
            color: #fff;
        }
      .paging-item.paging-item--disabled,
        .paging-item.paging-item--more{
            background-color: #fff;
            color: #505050;
        }
        /* //禁用 */
       .paging-item.paging-item--disabled {
            cursor: not-allowed;
            opacity: .75;
        }
        .paging-item.paging-item--more,
        .paging-item.paging-item--current {
            cursor: default;
        }
        /* //选中 */
        .paging-item.paging-item--current {
            background-color: #a21003;
            color:#fff;
            position: relative;
            z-index: 1;
            border-color: #a21003;
        }
.mo-paging .paging-item--next,.mo-paging .paging-item--prev{
	padding: .12rem .3rem;
}
@media screen and (max-width:480px) {
    .mo-paging .paging-item--next,.paging-item--prev{
            width: .3rem;
            padding: 0;
    }
    .mo-paging>.paging-item{
        min-width: .8rem;
        padding: .08rem .1rem;
        text-align: center;
    }
    
}
</style>
