<template>
  <div class="home">
    <banner :bannerIndex="3"/>
    <div class="public_title animate__animated animate__fadeInDown" v-if="fieldShow">新闻资讯</div>
    <div class="content_w news_one animate__animated animate__fadeInUp" v-if="fieldShow">
      <div class="news_btleft">
        <img src="../../static/img/newbt1.jpg" />
      </div>
      <div class="swiperBanner">
        <swiper class="swiper-Box news_swiper" :options="swiperOption"  v-if="recommend_news.length > 0">
          <swiper-slide
            v-for="(item, index) in recommend_news"
            :key="index"
            class="news_slide"
           
          >
            <div class="news_tubox animation_img" @click="goDetail(item.id)">
              <img :src="item.pic" />
            </div>
            <div class="news_cont" @click="goDetail(item.id)">
              <div class="news_cont_txt news_cont_listtxt" >
                {{item.title}}
              </div>
              <div class="news_cont_txt" >
                <span>{{item.add_time}}</span>
              </div>
              <div class="news_cont_p">
                {{item.describe}}
              </div>
              <div class="modular2_bt ny_more new_none_more " @click="goDetail(item.id)" v-if="recommend_news.length!=0">
                <div class="xian"></div>
                <span>查看详情</span>
                <div class="yuan"></div>
                <img src="../../static/img/rjt2.png" class="more_img" />
                <img src="../../static/img/rjt3.png" class="more_img1" />
              </div>
            </div>
          </swiper-slide>
          <!-- <div class="swiper-pagination1" slot="pagination"></div> -->
        </swiper>
      </div>
      <div class="news_btrigt">
        <img src="../../static/img/newbt2.jpg" />
      </div>
    </div>
    <div class="content_w">
      <div class="animate__animated animate__fadeInUp news_list" v-if="showTwo">
        <div
        class="news1_box"
          v-for="(item, index) in newsList"
          :key="index"
          @click="goDetail(item.id)"
        >
          <div class="news1_li">
            <div class="news1_time" :class="inwidth < 768 ? 'displayN' : ''">
              <span>{{ item.day }}</span>
              {{ item.year }}-{{ item.month }}
            </div>
            <div class="news1_cont">
              <h2>{{ item.title }}</h2>
              <p>{{ item.describe }}</p>
              <div class="modular2_bt ny_more list_more">
                  <div class="xian"></div>
                  <span>查看详情</span>
                  <div class="yuan"></div>
                  <img src="../../static/img/rjt2.png" class="more_img" />
                  <img src="../../static/img/rjt3.png" class="more_img1" />
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="animate__animated animate__fadeInUp pagination_box" v-if="showTwo && pageInfo.totalCount">
        <pagination
          :page-index="pageInfo.page"
          :total="pageInfo.totalCount"
          :page-size="list_row"
          :per-pages="pageInfo.pageCount"
          @change="pageChange"
        ></pagination>
    </div>
    <!-- && newsList.length !== 0 -->
    <div v-if="!showTwo" class="height2r"></div>
  </div>
</template>

<script>
import pagination from "../components/pagination";
import banner from "../components/home/banner.vue";
export default {
  name: "home",
  components: {
    banner,
    pagination
  },
  data() {
    return {
      showTwo:false,
      fieldShow:false,
      pageInfo:{},
      recommend_news:[],
      newsList:[],
      list_row: 10, //每页显示20条数据
      page: 1, //当前页码
      totalCount: 20, //总条数
      pageCount: 10,
      inwidth: window.innerWidth,
      swiperOption: {
        loop: true,
        autoplay: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        speed: 1500,
        navigation: {
          nextEl: ".news_btleft",
          prevEl: ".news_btrigt",
        },
      },
    };
  },
  mounted(){
    this.news_list();
    window.addEventListener("scroll", this.onScroll,true);
    if(this.inwidth > 768){
      setTimeout((res) => {
        this.fieldShow = true;
      }, 2500);
    }else{
      this.fieldShow = true;
      this.showTwo = true
    }
    
  },
  methods:{
    onScroll(){
				let inHeight = window.innerHeight
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if(scrollTop>0){
        this.fieldShow = true;
      }
      if(this.inwidth >= 1680 && scrollTop > 720){
				  this.showTwo = true
				}else if(this.inwidth >= 1560 && this.inwidth < 1680 && scrollTop > 720){
				  this.showTwo = true
				}else if(this.inwidth >= 1400 && this.inwidth < 1560 && scrollTop > 660){
				  this.showTwo = true
				}else if(this.inwidth >= 1200 && this.inwidth < 1400 && scrollTop > 600){
				  this.showTwo = true
				}else if(this.inwidth >= 1024 && this.inwidth < 1200 && scrollTop > 540){
				  this.showTwo = true
				}
		},
     goDetail(id) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          newsid: id
        }
      });
    },
    //从page组件传递过来的当前page
    pageChange(page) {
      console.log("page::", page);
      this.page = page;
      this.news_list()
    },
    news_list() {
      this.$api.news_list({
        page:this.page,
        keyword:'',
      }).then((res) => {
        if (res.status == 1) {
          this.newsList = res.data.news_list;
          this.pageInfo = res.data.pageInfo;
          this.recommend_news = res.data.recommend_news;
        } else {
          layer.msg(res.msg);
        }
      });
    },
    
  }
};
</script>
<style scoped>
/* .mt0{
  margin-top: 0;
} */
.news_one {
  background: #fafafa;
  margin-bottom: .5rem;
  padding: 0.2rem;
  position: relative;
}
.news_btleft,
.news_btrigt {
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 99;
  cursor: pointer;
}
.news_btrigt {
  right: 0;
  left: auto;
}
.news_swiper {
}
.news_slide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.news_tubox {
  width: 40%;
  cursor: pointer;
}
.news_tubox img {
  width: 100%;
  height: 3.5rem;
  object-fit: cover;
}
.news_cont {
  width: 50%;
  margin-left: 5%;
  margin-right: 5%;
  min-height: 1.5rem;
}
.news_cont_txt {
  font-size: 0.24rem;
  color: #333;
}
.news_cont_txt span {
  display: block;
  font-size: 0.16rem;
  color: #666;
  margin-top: 0.1rem;
}
.news_cont_p {
  font-size: 0.16rem;
  color: #4c4c4c;
  margin-top: 0.2rem;
  line-height: 0.3rem;
  height: 1rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news1_box {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.news1_li{
  display: flex;
  justify-content: space-between;
  padding-bottom: .3rem;
  padding-top: .4rem;
  margin-top: .15rem;
  border-bottom: 1px dashed #d4d4d4;
}
.news1_time {
  font-size: .18rem;
  color: #a21003;
  text-align: center;
}
.news1_time span {
  display: block;
  font-size: .48rem;
  line-height: 100%;
  font-weight: bold;
  text-align: center;
}


.news1_cont {
  flex: 1;
  margin-left: .3rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.news1_cont h2 {
  font-size: .16rem;
  color: #4c4c4c;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
}
.news_cont_listtxt{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 32px;

}
.news1_cont p {
  width: 100%;
  font-size: .14rem;
  color: #999;
  line-height: .3rem;
  margin-top: .2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news1_li:hover {
  -webkit-animation: mymove 0.5s 1 forwards; /* Chrome, Safari, Opera */
  animation: mymove 0.5s 1 forwards;
  z-index: 99;
  /* transform: scale(1.1); */
  transition: transform 0.2s linear 0s;
  box-shadow: rgba(53, 53, 53, 0.1) 0px 0px 20px;
  padding-left:.2rem;
  padding-right: .0rem;
  border-bottom: none;
}
 .news1_li:hover .news1_time {
    -webkit-animation: news1Time 0.5s 1 forwards; /* Chrome, Safari, Opera */
    animation: news1Time 0.5s 1 forwards;
  }
 .news1_li:hover .news1_bt {
    -webkit-animation: news1Bt 1.5s 1 forwards; /* Chrome, Safari, Opera */
    animation: news1Bt 1.5s 1 forwards;
  }
  /* Chrome, Safari, Opera */
  @-webkit-keyframes news1Time {
    100% {
      color: #a21003;
    }
  }
  /* Standard syntax */
  @keyframes news1Time {
    100% {
      color: #a21003;
    }
  }
  /* Chrome, Safari, Opera */
  @-webkit-keyframes news1Bt {
    100% {
      background: #a21003;
      border: 1px solid #a21003;
      color: #fff;
    }
  }
  /* Standard syntax */
  @keyframes news1Bt {
    100% {
      background: #a21003;
      border: 1px solid #a21003;
      color: #fff;
    }
  }
  /* Chrome, Safari, Opera */
  @-webkit-keyframes mymove {
    100% {
      margin-left: -.2rem;
    }
  }

  /* Standard syntax */
  @keyframes mymove {
    100% {
      margin-left: -.2rem;
    }
  }
  .news_list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .news_list .news1_box{
    width: 47%;
  }
  .list_more{
    margin-top: 0;
  }
  .pagination_box{
    margin: 1rem auto;
  }
@media (max-width: 1200px) {
  .new_none_more{
    margin-top: 0;
  }
  .news_cont_p{
    height: .8rem;
  }
}
@media (max-width: 1024px) {
  .news_list{
    display: block;
  }
  .news_list .news1_box{
    width: 100%;
  }
}
@media (max-width: 768px) {
  .news_cont_p{
    height: .6rem;
    margin-top: .1rem;
  }
}
@media (max-width: 480px) {
  .animation_img img{
    height: 5rem;
  }
  .news_slide{
    display: block;
  }
  .news_tubox{
    width: 100%;
  }
  .news_cont_txt{
    font-size: 18px;
    margin-top: .5rem;
  }
  .news_cont_txt span{
    font-size: 12px;
  }
  .news_cont{
    width: 90%;
  }
  .news_cont_p{
    font-size: 12px;
    margin-top: .3rem;
    line-height: 24px;
    height: auto;
    margin-bottom: .3rem;
  }
  .modular2_bt{
    font-size: 14px;
  }
  .news1_cont h2{
    font-size: 16px;
  }
  .news1_cont p{
    font-size: 12px;
    line-height: 24px;
  }
  .news1_time span{
    font-size: 32px;
  }
  .news1_time{
    font-size: 14px;
    text-align: center;
  }
  .list_more{
    display: none;
  }
  .news_btleft, .news_btrigt{
    top: 40%;
  }
}



  
 


</style>